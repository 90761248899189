<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('Countries') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body>
      <ul class="countries-stats list-unstyled">
        <li>
          <strong class="text-uppercase">{{ $t('sites.country') }}</strong>
          <strong class="text-uppercase">{{ $t('page.features.views') }}</strong>
        </li>
        <li
          v-for="i in sortCountries"
          :key="i.key"
        >
          <div
            class="box"
            @click="openCountry($event)"
          >
            <strong>{{ toCapitalize(i.key) }}</strong>
            <strong>
              {{ i.value ? percent(i.value, totalCountriesValues) : 0 }} / {{ Intl.NumberFormat('en-GB', { style: 'decimal' }).format(parseInt(i.value)) }}
              <template v-if="(i.city !== undefined && i.city.length !== 0)">
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
                <feather-icon
                  size="18"
                  icon="ChevronDownIcon"
                />
              </template>

            </strong>
            <div
              class="line-stats"
              :style="{ width: percent(i.value, totalCountriesValues) }"
            />
          </div>
          <div
            v-if="(i.city !== undefined && i.city.length !== 0)"
            class="cities"
          >
            <ul class="list-unstyled">
              <li
                v-for="c in i.city"
                :key="c.key"
              >
                <div class="box">
                  <span>{{ c.key }}</span>
                  <span>{{ percent(c.value, i.value) }} / {{ Intl.NumberFormat('en-GB', { style: 'decimal' }).format(parseInt(c.value)) }}</span>
                  <div
                    class="line-stats"
                    :style="{ width: percent(c.value, i.value) }"
                  />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
import toCapitalize from '@/utils/text-transform'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    subtitle: {
      type: String,
      default: () => '',
    },
    countryCity: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      sortCountries: [],
      toCapitalize,
    }
  },
  computed: {
    totalCountriesValues() {
      let total = 0
      this.countryCity.forEach(i => {
        total += i.value
      })
      return total
    },
  },
  created() {
    this.sortCountries = this.moveToEnd(this.countryCity.sort((a, b) => (a.value > b.value ? -1 : 1)))
  },
  methods: {
    openCountry(event) {
      const boxes = document.querySelectorAll('.countries-stats .box')
      if (!event.currentTarget.classList.contains('open')) {
        boxes.forEach(el => el.classList.remove('open'))
      }
      event.currentTarget.classList.toggle('open')
    },
    percent(value, field) {
      return `${((value / field) * 100).toFixed(2)}%`
    },
    moveToEnd(arr) {
      const filtered = arr.filter(el => el.key !== 'other')
      const other = arr.filter(el => el.key === 'other')
      return filtered.concat(Array(arr.length - filtered.length).fill(other[0]))
    },
  },
}
</script>

<style scoped>
.countries-stats .box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: 4px 0 6px;
}

.countries-stats .box:hover {
  background-color: #eceff1;
  color: #1a73e8;
}

.countries-stats > li > .box {
  border-bottom: 1px solid #eceff1;
  padding-right: 21px;
}

.countries-stats > li  strong {
  padding-left: 4px;
  padding-right: 4px;
}

.countries-stats > li > .box:hover {
  cursor: pointer;
}

.countries-stats > li:first-child {
  padding-top: 0;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eceff1;
}

.countries-stats li:last-child {
  border-bottom: 0;
}

.countries-stats .line-stats {
  background-color: #1a73e8;
  height: 2px;
  position: absolute;
  bottom: -1px;
  z-index: 1;
}

.countries-stats .cities {
  width: 100%;
  background: #fafafa;
  padding: 0;
  display: none;
}

.countries-stats .cities span {
  padding: 0 4px;
}

.countries-stats > li .box.open {
  background: #7367f0;
  color: #fff;
}

.countries-stats > li .box.open + .cities {
  display: block;
  margin-bottom: 25px;
  border-left: 1px dashed #9c9c9c;
  border-right: 1px dashed #9c9c9c;
}

.countries-stats > li .box .feather {
  position: absolute;
  right: 4px;
  top: 4px;
}

.countries-stats > li .box .feather-chevron-down {
  display: none
}

.countries-stats > li .box.open .feather-chevron-down {
  display: block
}

.countries-stats > li .box.open .feather-chevron-right {
  display: none
}

.countries-stats .cities .box {
  border-bottom: 1px dashed #9c9c9c;
}

.countries-stats .cities li:last-child {
  border-bottom: 0
}
</style>
