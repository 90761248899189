<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('Browsers') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(browser,index) in browserData"
        :key="`${browser.name}${index}`"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img
              :src="browser.browserImg"
              alt="browser img"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ browser.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ browser.usage }}</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
const chromeIcon = require('@/assets/images/icons/google-chrome.png')
const firefoxIcon = require('@/assets/images/icons/mozila-firefox.png')
const safariIcon = require('@/assets/images/icons/apple-safari.png')
const ieIcon = require('@/assets/images/icons/internet-explorer.png')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    VueApexCharts,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      sortRows: [],
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger, $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [],
      browserData: [],
      browsers: {
        BrowserChrome: {
          img: chromeIcon,
          name: 'Google Chrome',
        },
        Chrome: {
          img: chromeIcon,
          name: 'Chrome',
        },
        'Chrome Mobile': {
          img: chromeIcon,
          name: 'Chrome Mobile',
        },
        BrowserGoogleBot: {
          img: require('@/assets/images/icons/google-bot.png'),
          name: 'Google Bot',
        },
        BrowserSafari: {
          img: safariIcon,
          name: 'Apple Safari',
        },
        'Mobile Safari': {
          img: safariIcon,
          name: 'Mobile Safari',
        },
        BrowserYandex: {
          img: require('@/assets/images/icons/yandex-browser.png'),
          name: 'Yandex',
        },
        BrowserFirefox: {
          img: firefoxIcon,
          name: 'Mozila Firefox',
        },
        Firefox: {
          img: firefoxIcon,
          name: 'Firefox',
        },
        BrowserIE: {
          img: ieIcon,
          name: 'Internet Explorer',
        },
        Edge: {
          img: ieIcon,
          name: 'Edge',
        },
        BrowserOpera: {
          img: require('@/assets/images/icons/opera.png'),
          name: 'Opera',
        },
        BrowserSamsung: {
          img: require('@/assets/images/icons/samsung-browser.png'),
          name: 'Samsung browser',
        },
        other: {
          img: require('@/assets/images/icons/internet.png'),
          name: this.$t('Other'),
        },
      },
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  computed: {
    totalValues() {
      let total = 0
      this.rows.forEach(i => {
        total += i.value
      })
      return total
    },
  },
  created() {
    this.sortRows = this.moveToEnd(this.rows.sort((a, b) => (a.value > b.value ? -1 : 1)))
    this.setData()
    this.showData()
  },
  methods: {
    setData() {
      this.sortRows.forEach(r => {
        if (this.browsers[r.key] !== undefined) {
          const percent = (r.value / this.totalValues) * 100
          this.chartSeries.push(percent.toFixed(2))
          this.browserData.push(
            {
              browserImg: this.browsers[r.key].img,
              name: this.browsers[r.key].name,
              usage: `${r.value ? percent.toFixed(2) : 0}%`,
            },
          )
        }
      })
    },
    showData() {
      for (let i = 0; i < this.sortRows.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart))
        chartClone.options.colors[0] = this.chartColor[i]
        chartClone.series[0] = this.chartSeries[i]
        this.chartData.push(chartClone)
      }
    },
    moveToEnd(arr) {
      const filtered = arr.filter(el => el.key !== 'other')
      const other = arr.filter(el => el.key === 'other')
      return filtered.concat(Array(arr.length - filtered.length).fill(other[0]))
    },
  },
}
</script>
<style scoped>
[dir] .card-browser-states .browser-states:first-child {
  margin-top: 0;
}
</style>
