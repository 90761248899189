<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('page.features.impression') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body
      class="statistics-body"
    >
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="12"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ Intl.NumberFormat('en-GB', { style: 'decimal' }).format(parseInt(rows)) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t('Unique users') }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner,
  },
  props: {
    subtitle: {
      type: String,
      default: () => '',
    },
    rows: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      loading: true,
      statisticsItems: [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '',
          subtitle: '',
          customClass: 'mb-2 mb-xl-0',
        },
      ],
    }
  },
  created() {
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
}
</script>
