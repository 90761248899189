<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('banners.targetings.browser_language') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(language,index) in languageData"
        :key="`language${index}`"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img
              v-if="language.img"
              width="22"
              height="14"
              :src="language.img"
              alt="browser img"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ $t(language.key) }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ language.usage }}</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    VueApexCharts,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    subtitle: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      sortRows: [],
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [],
      languageData: [],
      languges: {
        ru: {
          img: require('@/assets/images/flags/ru.png'),
          key: 'Russian',
        },
        uk: {
          img: require('@/assets/images/flags/ua.png'),
          key: 'Ukrainian',
        },
        en: {
          img: require('@/assets/images/flags/en.png'),
          key: 'English',
        },
        he: {
          img: require('@/assets/images/flags/he.png'),
          key: 'Hebrew',
        },
        ja: {
          img: require('@/assets/images/flags/ja.png'),
          key: 'Japan',
        },
        th: {
          img: require('@/assets/images/flags/th.png'),
          key: 'Thailand',
        },
        other: {
          img: require('@/assets/images/flags/other.png'),
          key: 'Other',
        },
      },
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  computed: {
    totalValues() {
      let total = 0
      this.rows.forEach(i => {
        total += i.value
      })
      return total
    },
  },
  created() {
    this.sortRows = this.moveToEnd(this.rows.sort((a, b) => (a.value > b.value ? -1 : 1)))
    this.setData()
    this.showData()
  },
  methods: {
    setData() {
      const arrayData = {}
      this.sortRows.forEach(r => {
        const [key] = r.key.split('-')
        if (arrayData[key] !== undefined) {
          arrayData[key] += r.value
        } else {
          arrayData[key] = r.value
        }
      })

      Object.keys(arrayData).forEach(key => {
        if (this.languges[key] !== undefined) {
          const percent = (arrayData[key] / this.totalValues) * 100
          this.chartSeries.push(percent.toFixed(2))
          this.languageData.push(
            {
              img: this.languges[key].img,
              key: this.languges[key].key,
              usage: `${arrayData[key] ? percent.toFixed(2) : 0}% / ${new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(arrayData[key])}`,
            },
          )
        }
      })
    },
    showData() {
      for (let i = 0; i < this.sortRows.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart))
        chartClone.options.colors[0] = this.chartColor[i]
        chartClone.series[0] = this.chartSeries[i]
        this.chartData.push(chartClone)
      }
    },
    moveToEnd(arr) {
      const filtered = arr.filter(el => el.key !== 'other')
      const other = arr.filter(el => el.key === 'other')
      return filtered.concat(Array(arr.length - filtered.length).fill(other[0]))
    },
  },
}
</script>
<style scoped>
[dir] .card-browser-states .browser-states:first-child {
  margin-top: 0;
}
@media (max-width: 992px) {
  [dir] .card-browser-states .browser-states {
    display: block;
  }
}
</style>
