<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('Top 5 requests') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body>
      <div v-if="loading">
        <b-spinner label="Loading..." />
      </div>
      <ul
        v-else-if="sortRows.length !== 0"
        class="requests-stats list-unstyled"
      >
        <li>
          <strong class="text-uppercase">URL</strong>
          <strong class="text-uppercase">{{ $t('page.features.views') }}</strong>
        </li>
        <li
          v-for="i in sortRows"
          :key="i.key"
          v-b-tooltip.hover.top="percent(i.value)"
        >
          <span v-if="i.key !== '' && i.key !== 'other'">{{ i.key }}</span>
          <span v-else-if="i.key === 'other'">{{ toCapitalize(i.key) }}</span>
          <span v-else>{{ getSiteDomain() }}</span>
          <strong>{{ i.value ? Intl.NumberFormat('en-GB', { style: 'decimal' }).format(parseInt(i.value)) : 0 }}</strong>
          <div
            class="line-stats"
            :style="{ width: percent(i.value) }"
          />
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import toCapitalize from '@/utils/text-transform'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    subtitle: {
      type: String,
      default: () => '',
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      sortRows: [],
      toCapitalize,
    }
  },
  computed: {
    totalValues() {
      let total = 0
      this.sortRows.forEach(i => {
        total += i.value
      })
      return total
    },
  },
  created() {
    this.sortRows = this.moveToEnd(this.rows.sort((a, b) => (a.value > b.value ? -1 : 1)))
  },
  methods: {
    percent(value) {
      return `${value ? ((value / this.totalValues) * 100).toFixed(2) : 0}%`
    },
    moveToEnd(arr) {
      const filtered = arr.filter(el => el.key !== 'other')
      const other = arr.filter(el => el.key === 'other')
      this.loading = false
      return filtered.concat(Array(arr.length - filtered.length).fill(other[0]))
    },
    getSiteDomain() {
      const filtered = this.rows.filter(el => el.key !== 'other' && el.key !== '')
      if (filtered[0] !== undefined) {
        const link = (new URL(filtered[0].key))
        return `${link.protocol}//${link.hostname}`
      }
      return ''
    },
  },
}
</script>

<style scoped>
.requests-stats li {
  display: flex;
  justify-content: space-between;
  padding: 4px 0 6px;
  border-bottom: 1px solid #eceff1;
  position: relative;
}

.requests-stats li:not(:first-child):hover {
  background-color: #eceff1;
  color: #1a73e8;
}

.requests-stats li:last-child {
  border-bottom: 0;
}

.requests-stats .line-stats {
  background-color: #1a73e8;
  height: 2px;
  position: absolute;
  bottom: 0;
}
</style>>
