<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ $t('Os') }}</b-card-title>
        <b-card-text class="font-small-2">
          {{ subtitle }}
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="donut"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
  },
  props: {
    subtitle: {
      type: String,
      default: () => '',
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortRows: [],
      series: [],
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
        },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                },
                value: {
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(parseInt(val))}`
                  },
                },
                total: {
                  show: false,
                  fontSize: '1.5rem',
                  label: 'Operational',
                  formatter() {
                    return '31%'
                  },
                },
              },
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
      osLabels: {
        OSAndroid: 'Android',
        OSiOS: 'iOS',
        OSWindows: 'Windows',
        OSBot: 'Bot',
        OSMacOSX: 'Mac OS X',
        OSLinux: 'Linux',
        other: 'Other',
        Windows: 'Windows',
        Android: 'Android',
        iOS: 'iOS',
        'Mac OS X': 'Mac OS X',
        'Chrome OS': 'Chrome OS',
      },
    }
  },
  created() {
    if (this.rows.length !== 0) {
      this.sortRows = this.moveToEnd(this.rows.sort((a, b) => (a.value > b.value ? -1 : 1)))
      this.sortRows.forEach(c => {
        if (this.osLabels[c.key] !== undefined) {
          this.series.push(c.value)
          this.chartOptions.labels.push(this.osLabels[c.key])
        }
      })
    }
  },
  methods: {
    moveToEnd(arr) {
      const filtered = arr.filter(el => el.key !== 'other')
      const other = arr.filter(el => el.key === 'other')
      return filtered.concat(Array(arr.length - filtered.length).fill(other[0]))
    },
  },
}
</script>
