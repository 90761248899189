<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span>
          <strong>{{ $t('swal.attention') }}</strong> {{ $t('page.features.clicksourceshelptext') }}
        </span>
      </div>
    </b-alert>

    <b-table
      v-model="selectedRows"
      small
      :items="statsData"
      :fields="filterFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      striped
      foot-clone
    >
      <template #head(datetime)>
        {{ $t('message.tableHeader.date') }}
      </template>

      <template #head(show)>
        {{ $t('message.tableHeader.impressions') }}
      </template>

      <template #head(click)>
        {{ $t('message.tableHeader.clicks') }}
      </template>

      <template #head(ecpm)>
        eCPM
      </template>

      <template #head(ctr)>
        CTR
      </template>

      <template #head(conversion)>
        {{ $t('stats.conversion') }}
      </template>

      <template #head(amount)>
        {{ $t('message.tableHeader.spent') }}
      </template>

      <template #cell(datetime)="row">
        <span v-if="row">
          <a
            href="#"
            :class="row.item._showDetails === true ? 'text-secondary' : ''"
            @click.prevent="showDetails(row)"
          >
            <strong>{{ row.item.datetime }}</strong>
            <feather-icon
              class="ml-15"
              :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="20"
            />
          </a>
        </span>
        <span v-else>
          {{ row.item.datetime }}
        </span>
      </template>
      <template #row-details="{ item }">
        <div v-if="item">
          <div
            v-if="loading && !statsDay[item.datetime]"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </div>

          <b-table
            v-else-if="!loading && statsDay[item.datetime].length"
            small
            :items="statsDay[item.datetime]"
            :fields="filterFields"
            responsive
            striped
            thead-class="d-none"
            table-variant="primary"
          >
            <template #cell(datetime)="row">
              {{ getFormattedDateTime(row.item.datetime) }}
            </template>

            <template #cell(show)="data">
              {{ data.value ? data.value.toLocaleString('ru-RU') : 0 }}
            </template>

            <template #cell(click)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>

            <template #cell(ctr)="data">
              {{ data.item.click ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(data.item.click / data.item.show * 100) + '%' : 0 }}
            </template>

            <template #cell(ecpm)="data">
              {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 3 }).format(data.item.amount / data.item.show * 1000) }}
            </template>

            <template #cell(pixel)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>

            <template #cell(conversion)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>

            <template #cell(amount)="data">
              {{ data.value ? currency(account.currency, data.value) : 0 }}
            </template>
          </b-table>
        </div>
      </template>

      <template #cell(amount)="data">
        {{ data.value ? currency(account.currency, data.value) : 0 }}
      </template>

      <template #cell(show)="data">
        {{ data.value ? data.value.toLocaleString('ru-RU') : 0 }}
      </template>

      <template #cell(click)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(ctr)="data">
        {{ data.item.click ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(data.item.click / data.item.show * 100) + '%' : 0 }}
      </template>

      <template #cell(ecpm)="data">
        {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 3 }).format(data.item.amount / data.item.show * 1000) }}
      </template>

      <template #cell(conversion)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(pixel)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #foot(datetime)="data">
        <strong :data-label="data.label">{{ $t('Records') }}: {{ statsData.length }}</strong>
      </template>

      <template #foot(click)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('click')) }}</strong>
      </template>

      <template #foot(show)="data">
        <strong :data-label="data.label">{{ totalFormat('show').toLocaleString('ru-RU') }}</strong>
      </template>
      <template #foot(conversion)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('conversion')) }}</strong>
      </template>
      <template #foot(pixel)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('pixel')) }}</strong>
      </template>
      <template #foot(amount)="data">
        <strong :data-label="data.label">{{ currency(account.currency, totalAmount) }}</strong>
      </template>
      <template #foot(ctr)="data">
        <strong :data-label="data.label">{{ totalFormat('click') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(totalFormat('click') / totalFormat('show') * 100) : 0 }}%</strong>
      </template>
      <template #foot(ecpm)="data">
        <strong :data-label="data.label">{{ totalFormat('amount') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(totalFormat('amount') / totalFormat('show' ) * 1000) : 0 }}</strong>
      </template>
    </b-table>

  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import currencyFormatter from '@/utils/currency-formatter'
import { getUserData } from '@/auth/utils'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import {
  BTable,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAlert,
    BSpinner,
  },
  props: {
    statsData: {
      type: Array,
      required: true,
    },
    account: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      authUserData: getUserData(),
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      fields: [
        { key: 'datetime', label: 'Date', tdClass: 'td-width' },
        { key: 'show', label: 'Impressions', tdClass: 'td-width' },
        { key: 'click', label: 'Clicks', tdClass: 'td-width' },
        { key: 'ctr', label: 'CTR', tdClass: 'td-width' },
        { key: 'ecpm', label: 'eCpm', tdClass: 'td-width' },
        { key: 'pixel', label: 'Pixel', tdClass: 'td-width' },
        { key: 'conversion', label: 'Conversion', tdClass: 'td-width' },
        { key: 'amount', label: 'Spent', tdClass: 'td-width' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      statsDay: {},
      loading: false,
      selectedRows: [],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.statsData.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    totalArticles() {
      let totalArticles = 0
      this.statsData.forEach(i => {
        totalArticles += i.stats.default.article
      })
      return totalArticles
    },
    totalExchange() {
      let total = 0
      this.statsData.forEach(i => {
        total += i.stats.default.exchange
      })
      return total
    },
    totalAdvertising() {
      let total = 0
      this.statsData.forEach(i => {
        total += i.stats.default.creative
      })
      return total
    },
    filterFields() {
      let { fields } = this
      if (!this.totalFormat('conversion')) {
        fields = fields.filter(f => f.key !== 'conversion')
      }
      if (!this.account.show_amount && !this.authUserData.role.includes('admin')) {
        fields = fields.filter(f => f.key !== 'amount').filter(f => f.key !== 'ecpm')
      }
      return fields
    },
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.statsData.forEach(i => {
        total += i[field]
      })
      return total
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.datetime]) {
          this.getStatsDay(row.item.datetime)
        }
      }
    },
    async getStatsDay(day) {
      this.loading = true
      this.statsDay[day] = null
      const params = {
        campaign_id: this.$route.params.id,
        type: 'day',
        date: day,
      }

      const responseData = await useJwt.getCampaignStatsByType(params)
      this.statsDay[day] = responseData.data.campaignstats || null
      this.loading = false
    },
  },
}
</script>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 14%;
}
</style>
